header, nav, section, article, aside, footer {
  display: block; }

ul, h1, h2, h3, h4, address, figure {
  padding: 0;
  margin: 0;
  font-style: normal;
  list-style: none; }

img {
  border: none; }

/* ----------------------------------------------------------------
 * Transition
 *
 * usage: @include transition(all, 0.5s, ease-out);
 * --------------------------------------------------------------*/
/* ----------------------------------------------------------------
 * MediaQueries
 * http://thesassway.com/intermediate/responsive-web-design-in-sass-using-media-queries-in-sass-32
 * --------------------------------------------------------------*/
.gapless, .gallery, .gallery figure, .spots-overview .spot, .login form, .login form .input, header .meta-nav, footer, .meta-page .text-box, .association .infos .info-box .info, .association .managment .members, .members-page .filters, .members-page .members, .member .tag-box .tags, .spots-page .header-box .keyvisual .content-box, .lake-page, .lake-page .member-webcams .member-webcam, .lake-page .spots .spot .desc {
  font-size: 0;
  line-height: 0; }

/* ----------------------------------------------------------------
 * VARS
 * --------------------------------------------------------------*/
/* ----------------------------------------------------------------
 * BASE
 * --------------------------------------------------------------*/
html {
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch; }

body {
  font-family: "Titillium Web", sans-serif;
  font-size: 16px;
  line-height: 16px;
  padding: 0;
  margin: 0;
  color: #4c4c4c;
  background-color: #FFFFFF;
  overflow-x: hidden; }

/* ----------------------------------------------------------------
 * LINKS
 * --------------------------------------------------------------*/
a {
  color: #1692d1;
  text-decoration: none; }

a:hover, a:visited, a:link {
  color: #1692d1; }

/* ----------------------------------------------------------------
 * FONT-FORMATS
 * --------------------------------------------------------------*/
.tiny-headline, .spots-overview .spot .name, .allowed, .logout button, .member .tag-box .tags .tag, .lake-page .member-webcams .member-webcam .name {
  font-size: 12px;
  font-weight: 700;
  line-height: 15.6px; }

.small-headline, .weather-station .sensors .sensor .value, .weather-station .expand .label, .weather-station .not-logged-in a, header .meta-nav .item a, footer .main .meta-nav .item a, footer .main .imprint a, footer .partners .title, .member .portrait .send-message a, .lake-page .webcam-control-button-box .webcam-control-button, .lake-page .member-webcams .member-webcam-zoom-box .member-webcam .name {
  font-size: 14px;
  font-weight: 700;
  line-height: 15.4px;
  text-transform: uppercase; }
  @media only screen and (max-width: 500px) {
    .small-headline, .weather-station .sensors .sensor .value, .weather-station .expand .label, .weather-station .not-logged-in a, header .meta-nav .item a, footer .main .meta-nav .item a, footer .main .imprint a, footer .partners .title, .member .portrait .send-message a, .lake-page .webcam-control-button-box .webcam-control-button, .lake-page .member-webcams .member-webcam-zoom-box .member-webcam .name {
      font-size: 11.9px;
      line-height: 14px; } }

.normal-headline, .login form .input label, .login form .submit button, .login form .error, header .main-nav .item a, header .mini-nav .item-box .item, .members-page .filters .filter .header .label, .member .name {
  font-size: 16px;
  font-weight: 700;
  line-height: 17.6px;
  text-transform: uppercase; }
  @media only screen and (max-width: 500px) {
    .normal-headline, .login form .input label, .login form .submit button, .login form .error, header .main-nav .item a, header .mini-nav .item-box .item, .members-page .filters .filter .header .label, .member .name {
      font-size: 13.6px;
      line-height: 16px; } }

.big-headline, .weather-station .not-logged-in .title, .login form .input input, .article-text h2, .article-text h3, .association .keyvisual-box .content-box .stats .stat .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #FFFFFF;
  text-transform: uppercase; }
  @media only screen and (max-width: 500px) {
    .big-headline, .weather-station .not-logged-in .title, .login form .input input, .article-text h2, .article-text h3, .association .keyvisual-box .content-box .stats .stat .title {
      font-size: 15.3px;
      line-height: 18px; } }

.bigger-headline, .weather-station .label-box .label, .article-text h1, .page-link .label-box .label, .pagination-load-more .label-box .label, .article-teaser .label-box .label, .spots-page .lakes .lake-teaser .label-box .label, .association .infos .info-box .info .title, .association .managment .title, .lake-page .section-title, .lake-page .spots .spot .header .info .name {
  color: #FFFFFF;
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase; }
  @media only screen and (max-width: 800px) {
    .bigger-headline, .weather-station .label-box .label, .article-text h1, .page-link .label-box .label, .pagination-load-more .label-box .label, .article-teaser .label-box .label, .spots-page .lakes .lake-teaser .label-box .label, .association .infos .info-box .info .title, .association .managment .title, .lake-page .section-title, .lake-page .spots .spot .header .info .name {
      font-size: 36px;
      line-height: 36px; } }
  @media only screen and (max-width: 500px) {
    .bigger-headline, .weather-station .label-box .label, .article-text h1, .page-link .label-box .label, .pagination-load-more .label-box .label, .article-teaser .label-box .label, .spots-page .lakes .lake-teaser .label-box .label, .association .infos .info-box .info .title, .association .managment .title, .lake-page .section-title, .lake-page .spots .spot .header .info .name {
      font-size: 28px;
      line-height: 28px; } }

.huge-headline, .keyvisual-with-title .content-box .title-box .title, .association .keyvisual-box .content-box .stats .stat .value, .spots-page .header-box .keyvisual .content-box .title, .lake-page .header-box .keyvisual .content-box .title {
  color: #FFFFFF;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  text-transform: uppercase;
  word-break: break-word; }
  @media only screen and (max-width: 500px) {
    .huge-headline, .keyvisual-with-title .content-box .title-box .title, .association .keyvisual-box .content-box .stats .stat .value, .spots-page .header-box .keyvisual .content-box .title, .lake-page .header-box .keyvisual .content-box .title {
      font-size: 42px;
      line-height: 42px; } }

.tiny-copy, .date-modified, .members-page .filters .filter .header .current-item {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px; }

.small-copy, .weather-station .not-logged-in .info, footer .main .contact, footer .main .copyright, .member .info, .member .city, .member .contact {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px; }

.normal-copy, .members-page .filters .filter .items .filter-item .label, .members-page .filters .search-filter .header .input, .lake-page .spots .spot .desc .left, .lake-page .spots .spot .desc .right {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px; }

.big-copy, .article-text, .association .infos .info-box .info .desc, .members-page .no-member-available, .spots-page .header-box .keyvisual .content-box .desc {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px; }
  @media only screen and (max-width: 500px) {
    .big-copy, .article-text, .association .infos .info-box .info .desc, .members-page .no-member-available, .spots-page .header-box .keyvisual .content-box .desc {
      font-size: 15.3px;
      line-height: 20.4px; } }

.kirbytext, footer .main .contact, .article-text, .association .infos .info-box .info .desc, .member .info, .spots-page .header-box .keyvisual .content-box .desc, .lake-page .spots .spot .desc .left, .lake-page .spots .spot .desc .right {
  padding-bottom: 20px; }
  .kirbytext p, footer .main .contact p, .article-text p, .association .infos .info-box .info .desc p, .member .info p, .spots-page .header-box .keyvisual .content-box .desc p, .lake-page .spots .spot .desc .left p, .lake-page .spots .spot .desc .right p {
    margin-top: 0;
    margin-bottom: 2em; }
  .kirbytext figure, footer .main .contact figure, .article-text figure, .association .infos .info-box .info .desc figure, .member .info figure, .spots-page .header-box .keyvisual .content-box .desc figure, .lake-page .spots .spot .desc .left figure, .lake-page .spots .spot .desc .right figure {
    margin-bottom: 2em; }
    .kirbytext figure img, footer .main .contact figure img, .article-text figure img, .association .infos .info-box .info .desc figure img, .member .info figure img, .spots-page .header-box .keyvisual .content-box .desc figure img, .lake-page .spots .spot .desc .left figure img, .lake-page .spots .spot .desc .right figure img {
      width: 100%; }
    .kirbytext figure figcaption, footer .main .contact figure figcaption, .article-text figure figcaption, .association .infos .info-box .info .desc figure figcaption, .member .info figure figcaption, .spots-page .header-box .keyvisual .content-box .desc figure figcaption, .lake-page .spots .spot .desc .left figure figcaption, .lake-page .spots .spot .desc .right figure figcaption {
      color: #1692d1;
      font-size: 80%;
      font-weight: 700; }
    .kirbytext figure.video, footer .main .contact figure.video, .article-text figure.video, .association .infos .info-box .info .desc figure.video, .member .info figure.video, .spots-page .header-box .keyvisual .content-box .desc figure.video, .lake-page .spots .spot .desc .left figure.video, .lake-page .spots .spot .desc .right figure.video {
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;
      background-color: black; }
      .kirbytext figure.video iframe, footer .main .contact figure.video iframe, .article-text figure.video iframe, .association .infos .info-box .info .desc figure.video iframe, .member .info figure.video iframe, .spots-page .header-box .keyvisual .content-box .desc figure.video iframe, .lake-page .spots .spot .desc .left figure.video iframe, .lake-page .spots .spot .desc .right figure.video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .kirbytext ul, footer .main .contact ul, .article-text ul, .association .infos .info-box .info .desc ul, .member .info ul, .spots-page .header-box .keyvisual .content-box .desc ul, .lake-page .spots .spot .desc .left ul, .lake-page .spots .spot .desc .right ul, .kirbytext ol, footer .main .contact ol, .article-text ol, .association .infos .info-box .info .desc ol, .member .info ol, .spots-page .header-box .keyvisual .content-box .desc ol, .lake-page .spots .spot .desc .left ol, .lake-page .spots .spot .desc .right ol {
    padding-left: 4em;
    margin-bottom: 2em; }
    .kirbytext ul li, footer .main .contact ul li, .article-text ul li, .association .infos .info-box .info .desc ul li, .member .info ul li, .spots-page .header-box .keyvisual .content-box .desc ul li, .lake-page .spots .spot .desc .left ul li, .lake-page .spots .spot .desc .right ul li, .kirbytext ol li, footer .main .contact ol li, .article-text ol li, .association .infos .info-box .info .desc ol li, .member .info ol li, .spots-page .header-box .keyvisual .content-box .desc ol li, .lake-page .spots .spot .desc .left ol li, .lake-page .spots .spot .desc .right ol li {
      margin-bottom: 1em;
      line-height: 130%; }
  .kirbytext ul, footer .main .contact ul, .article-text ul, .association .infos .info-box .info .desc ul, .member .info ul, .spots-page .header-box .keyvisual .content-box .desc ul, .lake-page .spots .spot .desc .left ul, .lake-page .spots .spot .desc .right ul {
    list-style: disc; }
  .kirbytext a, footer .main .contact a, .article-text a, .association .infos .info-box .info .desc a, .member .info a, .spots-page .header-box .keyvisual .content-box .desc a, .lake-page .spots .spot .desc .left a, .lake-page .spots .spot .desc .right a {
    font-weight: 700;
    box-sizing: border-box;
    color: #bbca00;
    -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .kirbytext a:hover, footer .main .contact a:hover, .article-text a:hover, .association .infos .info-box .info .desc a:hover, .member .info a:hover, .spots-page .header-box .keyvisual .content-box .desc a:hover, .lake-page .spots .spot .desc .left a:hover, .lake-page .spots .spot .desc .right a:hover {
      color: #1692d1; }
    .kirbytext a.cta, footer .main .contact a.cta, .article-text a.cta, .association .infos .info-box .info .desc a.cta, .member .info a.cta, .spots-page .header-box .keyvisual .content-box .desc a.cta, .lake-page .spots .spot .desc .left a.cta, .lake-page .spots .spot .desc .right a.cta {
      font-size: 80%;
      text-transform: uppercase;
      border: 1px solid #1692d1;
      color: #1692d1;
      padding: 0.25em 0.75em;
      margin-right: 1em;
      border-radius: 3px;
      position: relative;
      top: -0.1em;
      -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .kirbytext a.cta:hover, footer .main .contact a.cta:hover, .article-text a.cta:hover, .association .infos .info-box .info .desc a.cta:hover, .member .info a.cta:hover, .spots-page .header-box .keyvisual .content-box .desc a.cta:hover, .lake-page .spots .spot .desc .left a.cta:hover, .lake-page .spots .spot .desc .right a.cta:hover {
        color: #FFFFFF;
        background-color: #1692d1; }
  .kirbytext em, footer .main .contact em, .article-text em, .association .infos .info-box .info .desc em, .member .info em, .spots-page .header-box .keyvisual .content-box .desc em, .lake-page .spots .spot .desc .left em, .lake-page .spots .spot .desc .right em {
    display: inline-block;
    font-style: normal;
    font-size: 80%;
    line-height: 130%; }

.text-overflow-ellipsis, .spots-overview .spot .name, .page-link .label-box .label, .pagination-load-more .label-box .label, .article-teaser .label-box .label, .spots-page .lakes .lake-teaser .label-box .label, .members-page .filters .filter .header .label, .members-page .filters .filter .header .current-item, .members-page .filters .filter .items .filter-item .label, .member .contact, .spots-page .lakes .lake-teaser .name, .lake-page .spots .spot .header .info .coords {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* ----------------------------------------------------------------
 * Boxes
 * --------------------------------------------------------------*/
.page-content-box, .meta-page .text-box, .association .infos .info-box .info, .association .managment, .members-page .no-member-available, .spots-page .header-box .keyvisual .content-box, .lake-page .spots .spot .header .info, .lake-page .spots .spot .desc {
  width: 90%;
  max-width: 1028px;
  margin: auto; }

/* ----------------------------------------------------------------
 * Modules
 * --------------------------------------------------------------*/
#disqus_thread {
  max-width: 800px;
  padding: 0 5% 20px;
  box-sizing: border-box;
  margin: auto; }

.keyvisual {
  position: relative;
  width: 100%;
  height: auto; }
  .keyvisual .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    background-color: #1692d1; }

.gallery {
  text-align: center;
  margin: auto;
  margin-bottom: 80px;
  max-width: 1100px; }
  .gallery figure {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px 10px;
    width: 200px; }
    @media only screen and (max-width: 800px) {
      .gallery figure {
        width: 29%; } }
    .gallery figure .thumbnail {
      width: 100%;
      padding-bottom: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      -webkit-transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .gallery figure .thumbnail:hover {
        transform: scale(1.05);
        box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.5); }
        @media only screen and (max-width: 800px) {
          .gallery figure .thumbnail:hover {
            transform: none;
            box-shadow: none; } }

.gallery-image-viewer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer; }
  .gallery-image-viewer .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); }
  .gallery-image-viewer .thumbnail {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; }
  .gallery-image-viewer img {
    position: absolute;
    top: 0;
    left: 0; }

.keyvisual-with-title {
  position: relative;
  margin-bottom: 40px; }
  .keyvisual-with-title .content-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .keyvisual-with-title .content-box .title-box {
      display: table;
      width: 100%;
      height: 100%; }
      .keyvisual-with-title .content-box .title-box .title {
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        box-sizing: border-box;
        padding: 0 10%; }
        @media only screen and (max-width: 500px) {
          .keyvisual-with-title .content-box .title-box .title {
            padding: 0 10px; } }
        .keyvisual-with-title .content-box .title-box .title .disqus-comments {
          display: block;
          font-size: 16px;
          line-height: 17.6px;
          color: white;
          opacity: 0.7; }

.spots-overview {
  width: 350px;
  border-top: 1px solid rgba(255, 255, 255, 0.5); }
  @media only screen and (max-width: 800px) {
    .spots-overview {
      width: 250px; } }
  .spots-overview .spot {
    padding: 2px 0 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
    .spots-overview .spot .name {
      color: white;
      text-align: left;
      text-transform: uppercase;
      display: inline-block;
      box-sizing: border-box;
      width: 230px;
      padding: 2px 10px;
      vertical-align: top; }
      @media only screen and (max-width: 800px) {
        .spots-overview .spot .name {
          width: 130px; } }

.wind-directions {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  transform: rotate(-15deg);
  background-color: rgba(255, 255, 255, 0.2); }
  .wind-directions .wind {
    position: absolute;
    left: 50%;
    width: 50%;
    height: 50%;
    background-image: url("../images/wind-direction.svg");
    background-repeat: no-repeat;
    background-size: contain;
    transform-origin: bottom left; }
    .wind-directions .wind.N {
      transform: rotate(0deg); }
    .wind-directions .wind.NNO {
      transform: rotate(22.5deg); }
    .wind-directions .wind.NO {
      transform: rotate(45deg); }
    .wind-directions .wind.ONO {
      transform: rotate(67.5deg); }
    .wind-directions .wind.O {
      transform: rotate(90deg); }
    .wind-directions .wind.OSO {
      transform: rotate(112.5deg); }
    .wind-directions .wind.SO {
      transform: rotate(135deg); }
    .wind-directions .wind.SSO {
      transform: rotate(157.5deg); }
    .wind-directions .wind.S {
      transform: rotate(180deg); }
    .wind-directions .wind.SSW {
      transform: rotate(202.5deg); }
    .wind-directions .wind.SW {
      transform: rotate(225deg); }
    .wind-directions .wind.WSW {
      transform: rotate(247.5deg); }
    .wind-directions .wind.W {
      transform: rotate(270deg); }
    .wind-directions .wind.WNW {
      transform: rotate(292.5deg); }
    .wind-directions .wind.NW {
      transform: rotate(315deg); }
    .wind-directions .wind.NNW {
      transform: rotate(337.5deg); }

.allowed {
  color: white;
  text-transform: uppercase;
  font-size: 9.6px;
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 0 0;
  vertical-align: top;
  width: 100px;
  text-align: center;
  border-radius: 10px;
  background-color: #7eb51f; }
  .allowed.is-not-allowed {
    background-color: #c94949; }

.weather-station {
  position: relative;
  width: 100%;
  background-color: #1692d1; }
  .weather-station .label-box {
    display: table;
    width: 50%; }
    @media only screen and (max-width: 500px) {
      .weather-station .label-box {
        visibility: hidden; } }
    .weather-station .label-box .label {
      display: table-cell;
      position: relative;
      height: 120px;
      box-sizing: border-box;
      vertical-align: middle;
      padding: 0 60px 0 10%; }
      @media only screen and (max-width: 800px) {
        .weather-station .label-box .label {
          font-size: 32px;
          line-height: 32px; } }
  .weather-station .update-bar {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    opacity: 0.5; }
  .weather-station .arrow {
    z-index: 1000;
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    border-radius: 5px;
    transform: rotate(90deg) translateY(12px);
    background-color: #1692d1;
    width: 25px;
    height: 50px;
    background-image: url("../images/arrow.svg");
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
    .weather-station .arrow:hover {
      background-color: #1172a3; }
  .weather-station .sensors {
    padding: 10px 5% 10px 0;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0; }
    @media only screen and (max-width: 500px) {
      .weather-station .sensors {
        padding: 10px 0 0;
        width: 100%;
        text-align: center;
        right: inherit; } }
    .weather-station .sensors .sensor {
      display: inline-block;
      position: relative;
      width: 100px;
      height: 100px; }
      @media only screen and (max-width: 800px) {
        .weather-station .sensors .sensor {
          width: 90px;
          height: 90px; } }
      .weather-station .sensors .sensor .wind-direction-graph {
        background-image: url("../images/wind-direction-full.svg"); }
      .weather-station .sensors .sensor .temperature-graph {
        background-image: url("../images/temperature.svg"); }
      .weather-station .sensors .sensor .rain-graph {
        background-image: url("../images/rain.svg"); }
      .weather-station .sensors .sensor .graph {
        position: absolute;
        left: 10%;
        width: 80%;
        height: 80%;
        background-repeat: no-repeat;
        background-size: contain; }
        @media only screen and (max-width: 800px) {
          .weather-station .sensors .sensor .graph {
            left: 15%;
            top: 5%;
            width: 70%;
            height: 70%; } }
      .weather-station .sensors .sensor .value {
        position: absolute;
        width: 100%;
        bottom: 0;
        text-align: center;
        text-transform: none;
        color: #FFFFFF; }
        .weather-station .sensors .sensor .value .subvalue {
          font-weight: normal;
          padding-left: 3px;
          opacity: 0.5; }
  .weather-station .expand {
    position: relative;
    margin: 0 5%;
    overflow: hidden;
    height: 0;
    opacity: 0; }
    .weather-station .expand .label {
      color: #FFFFFF;
      margin: 0 0 25px;
      padding-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      text-align: center; }
      .weather-station .expand .label .wind-color {
        padding-bottom: 0;
        border-bottom: 3px solid #FFFFFF; }
      .weather-station .expand .label .gust-color {
        padding-bottom: 0;
        border-bottom: 3px solid #f5ab00; }
    .weather-station .expand .winddirection-chart-box {
      width: 100%;
      height: 30px;
      display: table;
      padding: 0 5% 0 5%;
      box-sizing: border-box;
      margin-bottom: 10px; }
      @media only screen and (max-width: 500px) {
        .weather-station .expand .winddirection-chart-box {
          height: 20px; } }
      .weather-station .expand .winddirection-chart-box .direction-graph {
        display: table-cell;
        background-image: url("../images/wind-direction-full.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center; }
    .weather-station .expand .windspeed-chart-box {
      width: 100%;
      height: 250px; }
  .weather-station .not-logged-in {
    position: relative;
    padding: 20px 5% 30px;
    max-width: 700px; }
    .weather-station .not-logged-in .title {
      color: white;
      padding-bottom: 10px; }
    .weather-station .not-logged-in .info {
      color: white;
      padding-bottom: 20px; }
    .weather-station .not-logged-in a {
      cursor: pointer;
      padding: 3px 10px 4px;
      margin: 0;
      width: 150px; }
      .weather-station .not-logged-in a.member-button {
        color: #1692d1;
        margin-right: 7px;
        background-color: #FFFFFF; }
        .weather-station .not-logged-in a.member-button:hover {
          background-color: rgba(255, 255, 255, 0.9); }
      .weather-station .not-logged-in a.non-member-button {
        color: #FFFFFF;
        background-color: #bbca00; }
        .weather-station .not-logged-in a.non-member-button:hover {
          background-color: #b2c000; }
  .weather-station .login {
    opacity: 0; }
    .weather-station .login form {
      top: 35%; }

.login {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
  .login form {
    position: absolute;
    display: block;
    width: 70%;
    margin: 0 15%; }
    @media only screen and (max-width: 800px) {
      .login form {
        width: 80%;
        margin: 0 10%; } }
    .login form .input {
      display: inline-block;
      width: 75%; }
      @media only screen and (max-width: 500px) {
        .login form .input {
          width: 100%; } }
      .login form .input label {
        display: inline-block;
        color: #1692d1;
        width: 33.333%;
        height: 40px;
        box-sizing: border-box;
        padding: 11px 15px;
        vertical-align: top;
        text-align: center;
        background-color: #FFFFFF;
        border-right: 1px solid #1692d1; }
        @media only screen and (max-width: 500px) {
          .login form .input label {
            padding: 1px 3px;
            width: 100%;
            height: 20px;
            border-right: none;
            border-bottom: 1px solid #1692d1; } }
      .login form .input input {
        display: inline-block;
        color: #4c4c4c;
        padding: 0;
        width: 66.666%;
        height: 40px;
        text-align: center;
        background-color: #FFFFFF;
        border: none;
        box-sizing: border-box;
        border-right: 1px solid #1692d1; }
        @media only screen and (max-width: 500px) {
          .login form .input input {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #1692d1; } }
    .login form .submit {
      display: inline-block;
      width: 24%;
      height: 40px;
      vertical-align: top; }
      @media only screen and (max-width: 500px) {
        .login form .submit {
          width: 100%; } }
      .login form .submit button {
        font-family: "Titillium Web", sans-serif;
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        color: white;
        background-color: #f5ab00; }
        .login form .submit button:hover {
          background-color: #eba400; }
    .login form .error {
      color: #f5ab00;
      text-align: center;
      padding-top: 20px; }

.logout {
  position: absolute;
  bottom: -22px;
  right: 0;
  z-index: 1000; }
  .logout button {
    font-family: "Titillium Web", sans-serif;
    cursor: pointer;
    border: 0;
    padding: 3px 10px 4px;
    margin: 0;
    color: white;
    width: 167px;
    background-color: #c94949; }
    .logout button:hover {
      background-color: #c74141; }

/* ----------------------------------------------------------------
 * HEADER
 * --------------------------------------------------------------*/
header {
  position: relative;
  box-sizing: border-box;
  height: 105px; }
  @media only screen and (max-width: 800px) {
    header {
      height: 115px; } }
  header .logo {
    position: absolute;
    top: 17px;
    left: 21px;
    background-image: url("../images/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 200px;
    height: 71px; }
    @media only screen and (max-width: 800px) {
      header .logo {
        top: 7px;
        left: 13px;
        width: 160px; } }
  header .main-nav {
    position: absolute;
    right: 200px;
    bottom: 26px; }
    @media only screen and (max-width: 800px) {
      header .main-nav {
        right: 21px;
        bottom: 50px; } }
    header .main-nav .item {
      display: inline-block;
      padding-left: 20px; }
      header .main-nav .item:first-child {
        padding-left: 0; }
      header .main-nav .item a {
        color: #4c4c4c; }
        header .main-nav .item a.selected {
          border-bottom: 3px solid #4c4c4c; }
        header .main-nav .item a:hover {
          color: #1692d1; }
  header .meta-nav {
    position: absolute;
    right: 0;
    top: 0;
    width: 167px; }
    @media only screen and (max-width: 800px) {
      header .meta-nav {
        width: 100%;
        top: auto;
        bottom: 0; } }
    header .meta-nav .item {
      position: relative;
      width: 100%;
      height: 35px;
      box-sizing: border-box;
      text-align: center; }
      @media only screen and (max-width: 800px) {
        header .meta-nav .item {
          display: inline-block;
          height: 29.75px;
          width: 33.33%; } }
      header .meta-nav .item:nth-child(3n+1) {
        background-color: #1692d1; }
      header .meta-nav .item:nth-child(3n+2) {
        background-color: #bbca00; }
      header .meta-nav .item:nth-child(3n+0) {
        background-color: #f5ab00; }
      header .meta-nav .item:hover .rollover {
        width: 100%; }
      header .meta-nav .item .rollover {
        position: absolute;
        width: 0;
        height: 100%;
        right: 0;
        background-color: rgba(76, 76, 76, 0.2);
        -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      header .meta-nav .item a {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 8.5px;
        position: relative;
        color: #FFFFFF;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
        @media only screen and (max-width: 800px) {
          header .meta-nav .item a {
            padding: 5.5px; } }
        @media only screen and (max-width: 500px) {
          header .meta-nav .item a {
            padding: 7px 0; } }
  header .mini-nav {
    position: absolute;
    width: 100%; }
    header .mini-nav .hamburger {
      position: absolute;
      user-select: none;
      cursor: pointer;
      top: 55px;
      right: 200px;
      width: 25px; }
      @media only screen and (max-width: 800px) {
        header .mini-nav .hamburger {
          top: 33px;
          right: 21px;
          width: 25px; } }
      header .mini-nav .hamburger .bar {
        background-color: #4c4c4c;
        width: 25px;
        height: 4px;
        margin-top: 4px; }
        header .mini-nav .hamburger .bar:first-child {
          margin-top: 0; }
    header .mini-nav .item-box {
      position: absolute;
      top: 95px;
      width: 100%;
      overflow: hidden; }
      @media only screen and (max-width: 800px) {
        header .mini-nav .item-box {
          top: 65px; } }
      header .mini-nav .item-box .item {
        color: #4c4c4c;
        text-align: center;
        display: block;
        padding: 10px 21px;
        border-bottom: 1px solid rgba(102, 102, 102, 0.1);
        letter-spacing: 0.5px; }
        header .mini-nav .item-box .item:first-child {
          border-top: 1px solid rgba(102, 102, 102, 0.1); }

/* ----------------------------------------------------------------
 * FOOTER
 * --------------------------------------------------------------*/
footer {
  background-color: #5c5c5c; }
  footer .main {
    position: relative;
    box-sizing: border-box;
    padding: 40px 5%; }
    footer .main .contact {
      display: inline-block;
      width: 50%;
      color: #FFFFFF;
      vertical-align: top; }
      @media only screen and (max-width: 500px) {
        footer .main .contact {
          width: 100%;
          text-align: center; } }
      footer .main .contact a {
        color: #FFFFFF;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        text-transform: none;
        font-weight: 400;
        font-size: 100%; }
        footer .main .contact a:hover {
          background-color: transparent;
          color: #1692d1; }
    footer .main .meta-nav {
      display: inline-block;
      width: 50%;
      text-align: right;
      margin-bottom: 40px;
      vertical-align: top; }
      @media only screen and (max-width: 500px) {
        footer .main .meta-nav {
          width: 100%;
          text-align: center; } }
      footer .main .meta-nav .item {
        display: inline-block;
        padding-left: 20px; }
        @media only screen and (max-width: 500px) {
          footer .main .meta-nav .item {
            display: block;
            padding: 8px 0; } }
        footer .main .meta-nav .item:first-child {
          padding-left: 0; }
        footer .main .meta-nav .item:nth-child(3n+1) {
          color: #1692d1; }
        footer .main .meta-nav .item:nth-child(3n+2) {
          color: #bbca00; }
        footer .main .meta-nav .item:nth-child(3n+0) {
          color: #f5ab00; }
        footer .main .meta-nav .item a {
          color: inherit; }
          footer .main .meta-nav .item a:hover {
            border-bottom: 2px solid; }
    footer .main .imprint {
      position: relative;
      margin-top: -46px;
      text-align: right;
      margin-bottom: 40px; }
      @media only screen and (max-width: 500px) {
        footer .main .imprint {
          margin-top: 0;
          text-align: center; } }
      footer .main .imprint a {
        color: #1692d1; }
    footer .main .copyright {
      text-align: center;
      color: rgba(255, 255, 255, 0.2); }
      footer .main .copyright p {
        margin: 0; }
  footer .partners {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 40px 40px 30px; }
    footer .partners .title {
      color: #FFFFFF; }
    footer .partners .partnerList {
      margin: 20px 0 0 0;
      padding: 0;
      list-style: none; }
      footer .partners .partnerList .partner {
        margin: 30px;
        display: inline-block; }
        @media only screen and (max-width: 800px) {
          footer .partners .partnerList .partner {
            margin: 15px; } }
        footer .partners .partnerList .partner .logo {
          height: 60px;
          opacity: 0.5;
          border: none;
          -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
          footer .partners .partnerList .partner .logo:hover {
            transform: scale(1.1);
            opacity: 1; }
          @media only screen and (max-width: 800px) {
            footer .partners .partnerList .partner .logo {
              height: 30px; } }

/* ----------------------------------------------------------------
 * Article
 * --------------------------------------------------------------*/
.article-text {
  max-width: 800px;
  padding: 0 5% 20px;
  box-sizing: border-box;
  margin: auto;
  color: #666666; }
  .article-text h1 {
    color: #1692d1;
    padding-top: 15px;
    padding-bottom: 20px; }
  .article-text h2 {
    color: #1692d1;
    padding-top: 15px;
    padding-bottom: 20px; }
  .article-text h3 {
    color: #4c4c4c;
    text-transform: none;
    padding-bottom: 10px; }
  .article-text ul {
    padding-left: 20px; }

.date-modified {
  display: block;
  font-style: italic;
  text-align: center;
  opacity: 0.5;
  margin-bottom: 50px; }

/* ----------------------------------------------------------------
 * Meta-Page
 * --------------------------------------------------------------*/
.meta-page {
  margin-bottom: 30px; }
  .meta-page .text-box .article-text {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    padding: 0 25px; }
    @media only screen and (max-width: 800px) {
      .meta-page .text-box .article-text {
        padding: 0;
        width: 100%; } }

/* ----------------------------------------------------------------
 * Page-Link
 * --------------------------------------------------------------*/
.page-link, .pagination-load-more, .article-teaser, .spots-page .lakes .lake-teaser {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 120px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #1692d1;
  text-align: left;
  -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  @media only screen and (max-width: 500px) {
    .page-link, .pagination-load-more, .article-teaser, .spots-page .lakes .lake-teaser {
      height: 84px; } }
  .page-link:hover, .pagination-load-more:hover, .article-teaser:hover, .spots-page .lakes .lake-teaser:hover {
    background-color: #1172a3; }
    .page-link:hover .label-box .arrow, .pagination-load-more:hover .label-box .arrow, .article-teaser:hover .label-box .arrow, .spots-page .lakes .lake-teaser:hover .label-box .arrow {
      right: 4%; }
  .page-link .label-box, .pagination-load-more .label-box, .article-teaser .label-box, .spots-page .lakes .lake-teaser .label-box {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: auto; }
    .page-link .label-box .label, .pagination-load-more .label-box .label, .article-teaser .label-box .label, .spots-page .lakes .lake-teaser .label-box .label {
      color: #FFFFFF;
      position: relative;
      display: table-cell;
      width: 90%;
      box-sizing: border-box;
      vertical-align: middle;
      padding: 0 60px 0 5%; }
    .page-link .label-box .arrow, .pagination-load-more .label-box .arrow, .article-teaser .label-box .arrow, .spots-page .lakes .lake-teaser .label-box .arrow {
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-25px);
      width: 25px;
      height: 50px;
      background-image: url("../images/arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
      transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      @media only screen and (max-width: 500px) {
        .page-link .label-box .arrow, .pagination-load-more .label-box .arrow, .article-teaser .label-box .arrow, .spots-page .lakes .lake-teaser .label-box .arrow {
          transform: translateY(-20px);
          width: 20px;
          height: 40px; } }

/* ----------------------------------------------------------------
 * Pagination
 * --------------------------------------------------------------*/
.pagination-load-more:hover .label-box .arrow {
  right: 5%;
  transform: rotate(90deg) translate(-25px, 0px); }

.pagination-load-more .label-box .arrow {
  transform: translateY(-25px);
  width: 50px;
  height: 50px;
  background-image: url("../images/plus.svg"); }

/* ----------------------------------------------------------------
 * ArticleTeaser
 * --------------------------------------------------------------*/
.article-teaser, .spots-page .lakes .lake-teaser {
  height: 250px; }
  @media only screen and (max-width: 800px) {
    .article-teaser, .spots-page .lakes .lake-teaser {
      height: 200px; } }
  @media only screen and (max-width: 500px) {
    .article-teaser, .spots-page .lakes .lake-teaser {
      height: 175px; } }
  .article-teaser:nth-child(3n+1), .spots-page .lakes .lake-teaser:nth-child(3n+1) {
    background-color: #1692d1; }
  .article-teaser:nth-child(3n+2), .spots-page .lakes .lake-teaser:nth-child(3n+2) {
    background-color: #bbca00; }
  .article-teaser:nth-child(3n+0), .spots-page .lakes .lake-teaser:nth-child(3n+0) {
    background-color: #f5ab00; }
  .article-teaser:hover .background, .spots-page .lakes .lake-teaser:hover .background {
    opacity: 0.1; }
  .article-teaser .label-box .label, .spots-page .lakes .lake-teaser .label-box .label {
    white-space: normal;
    word-break: break-word;
    padding-top: 10px;
    padding-bottom: 10px; }
    .article-teaser .label-box .label .disqus-comment-count, .spots-page .lakes .lake-teaser .label-box .label .disqus-comment-count {
      font-size: 14px;
      line-height: 15.4px;
      opacity: 0.7; }
  .article-teaser .background, .spots-page .lakes .lake-teaser .background {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }

/* ----------------------------------------------------------------
 * Association
 * --------------------------------------------------------------*/
.association .keyvisual-box {
  position: relative; }
  .association .keyvisual-box .keyvisual .overlay {
    background-color: #f5ab00; }
  .association .keyvisual-box .content-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .association .keyvisual-box .content-box .stats {
      position: absolute;
      top: 50%;
      transform: translateY(-98.5px);
      left: 0;
      width: 100%;
      text-align: center; }
      @media only screen and (max-width: 800px) {
        .association .keyvisual-box .content-box .stats {
          transform: translateY(-63.1px); } }
      @media only screen and (max-width: 500px) {
        .association .keyvisual-box .content-box .stats {
          transform: translateY(-45.4px); } }
      .association .keyvisual-box .content-box .stats .stat {
        position: relative;
        display: inline-table;
        width: 177px;
        height: 177px;
        margin: 0 20px; }
        @media only screen and (max-width: 800px) {
          .association .keyvisual-box .content-box .stats .stat {
            width: 106.2px;
            height: 106.2px;
            margin: 0 10px; } }
        @media only screen and (max-width: 500px) {
          .association .keyvisual-box .content-box .stats .stat {
            width: 70.8px;
            height: 70.8px;
            margin: 0 10px; } }
        .association .keyvisual-box .content-box .stats .stat .title {
          color: white;
          position: absolute;
          bottom: -35px;
          width: 100%;
          text-align: center; }
          @media only screen and (max-width: 800px) {
            .association .keyvisual-box .content-box .stats .stat .title {
              bottom: -25px;
              font-size: 16px; } }
          @media only screen and (max-width: 500px) {
            .association .keyvisual-box .content-box .stats .stat .title {
              bottom: -25px;
              font-size: 14px; } }
        .association .keyvisual-box .content-box .stats .stat .value {
          font-size: 100px;
          color: white;
          display: table-cell;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          text-align: center;
          vertical-align: middle;
          box-sizing: border-box;
          border: 3px solid white; }
          @media only screen and (max-width: 800px) {
            .association .keyvisual-box .content-box .stats .stat .value {
              font-size: 72px;
              border: 2px solid white; } }
          @media only screen and (max-width: 500px) {
            .association .keyvisual-box .content-box .stats .stat .value {
              font-size: 48px;
              border: 2px solid white; } }

.association .infos .info-box:nth-child(3n+1) {
  background-color: #1692d1; }

.association .infos .info-box:nth-child(3n+2) {
  background-color: #bbca00; }

.association .infos .info-box:nth-child(3n+0) {
  background-color: #f5ab00; }

.association .infos .info-box .info {
  padding-top: 40px;
  padding-bottom: 10px; }
  .association .infos .info-box .info .title {
    display: inline-block;
    vertical-align: top;
    width: 35%; }
    @media only screen and (max-width: 800px) {
      .association .infos .info-box .info .title {
        width: 100%;
        padding-bottom: 20px; } }
  .association .infos .info-box .info .desc {
    color: white;
    display: inline-block;
    vertical-align: top;
    width: 65%; }
    @media only screen and (max-width: 800px) {
      .association .infos .info-box .info .desc {
        width: 100%; } }
    .association .infos .info-box .info .desc a {
      color: white;
      border-color: white; }
      .association .infos .info-box .info .desc a.cta {
        color: white;
        border-color: white; }
        .association .infos .info-box .info .desc a.cta:hover {
          color: #1692d1;
          border-color: white;
          background-color: white; }
    .association .infos .info-box .info .desc ul, .association .infos .info-box .info .desc ol {
      padding: 0 20px; }

.association .managment {
  padding-top: 40px;
  margin-bottom: 40px; }
  .association .managment .title {
    color: #1692d1;
    text-align: center; }
  .association .managment .members {
    margin-top: 40px; }
    .association .managment .members .member {
      width: 33.33%; }
      @media only screen and (max-width: 500px) {
        .association .managment .members .member {
          width: 50%; } }
      .association .managment .members .member .city, .association .managment .members .member .tag-box, .association .managment .members .member .send-message {
        display: none; }

/* ----------------------------------------------------------------
 * Members
 * --------------------------------------------------------------*/
.members-page {
  position: relative; }
  .members-page .keyvisual-with-title {
    margin: 0; }
  .members-page .login {
    position: relative; }
    .members-page .login .keyvisual-with-title {
      margin: 0; }
      @media only screen and (max-width: 500px) {
        .members-page .login .keyvisual-with-title .content-box {
          height: 50%; } }
    .members-page .login form {
      bottom: 15%; }
  .members-page .filters {
    user-select: none;
    position: relative;
    z-index: 10;
    padding-bottom: 30px; }
    .members-page .filters .filter {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: top;
      width: 25%;
      background-color: #1692d1; }
      @media only screen and (max-width: 500px) {
        .members-page .filters .filter {
          width: 50%; } }
      .members-page .filters .filter:hover {
        background-color: #158cc8; }
      .members-page .filters .filter.skills {
        background-color: #bbca00; }
        .members-page .filters .filter.skills:hover {
          background-color: #b2c000; }
      .members-page .filters .filter.hobbies {
        background-color: #f5ab00; }
        .members-page .filters .filter.hobbies:hover {
          background-color: #eba400; }
      .members-page .filters .filter.opened {
        z-index: 100; }
        .members-page .filters .filter.opened .header .arrow-icon {
          transform: rotate(90deg) scale(-1, 1); }
        .members-page .filters .filter.opened .items {
          display: block; }
      .members-page .filters .filter .header {
        height: 60px;
        cursor: pointer; }
        .members-page .filters .filter .header .label {
          color: white;
          box-sizing: border-box;
          padding: 20px 20px 2px;
          width: 95%;
          display: block; }
        .members-page .filters .filter .header .current-item {
          position: relative;
          text-transform: none;
          width: 70%;
          opacity: 0.9;
          padding-left: 20px;
          color: white;
          box-sizing: border-box; }
        .members-page .filters .filter .header .arrow-icon {
          -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
          position: absolute;
          width: 22px;
          height: 22px;
          top: 17px;
          right: 10px;
          background-image: url("../../assets/images/small-arrow.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transform: rotate(90deg); }
          @media only screen and (max-width: 800px) {
            .members-page .filters .filter .header .arrow-icon {
              width: 18px;
              height: 18px;
              top: 19px;
              right: 7px; } }
      .members-page .filters .filter .items {
        display: none;
        position: absolute;
        background-color: inherit;
        height: 0;
        width: 100%;
        overflow-y: scroll; }
        .members-page .filters .filter .items .filter-item {
          height: 30px;
          cursor: pointer;
          box-sizing: border-box;
          border-top: 1px solid rgba(255, 255, 255, 0.5); }
          .members-page .filters .filter .items .filter-item:hover {
            background-color: rgba(0, 0, 0, 0.1); }
          .members-page .filters .filter .items .filter-item.selected {
            background-image: url("../../assets/images/filter-active-icon.svg");
            background-repeat: no-repeat;
            background-size: 18px;
            background-position: right 13px center; }
            .members-page .filters .filter .items .filter-item.selected .label {
              font-weight: 700 !important; }
          .members-page .filters .filter .items .filter-item .label {
            width: 100%;
            display: block;
            color: white;
            padding: 3px 20px; }
    .members-page .filters .search-filter {
      background-color: #4c4c4c; }
      .members-page .filters .search-filter:hover {
        background-color: #474747; }
      .members-page .filters .search-filter .header {
        position: relative; }
        .members-page .filters .search-filter .header .magnifier {
          position: absolute;
          width: 20px;
          height: 20px;
          top: 20px;
          left: 20px;
          background-image: url("../../assets/images/magnifier.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; }
        .members-page .filters .search-filter .header .input {
          letter-spacing: -0.5px;
          position: absolute;
          box-sizing: border-box;
          padding: 10px 20px 10px 50px;
          top: 0;
          left: 0;
          color: white;
          background: none;
          border: none;
          width: 100%;
          height: 100%; }
          @media only screen and (max-width: 800px) {
            .members-page .filters .search-filter .header .input {
              font-size: 14px; } }
  .members-page .members {
    margin: 20px 20px 40px; }
    .members-page .members .member {
      font-size: 16px;
      line-height: normal;
      width: 25%;
      cursor: pointer;
      height: 260px; }
      @media only screen and (max-width: 800px) {
        .members-page .members .member {
          height: 180px;
          width: 33%; } }
      @media only screen and (max-width: 500px) {
        .members-page .members .member {
          width: 50%; } }
      .members-page .members .member:hover {
        position: relative;
        z-index: 9; }
        .members-page .members .member:hover .city {
          visibility: hidden; }
        .members-page .members .member:hover .tag-box {
          visibility: visible;
          opacity: 1;
          margin-top: -23px; }
        .members-page .members .member:hover .send-message {
          opacity: 1;
          transform: scale(0.95); }
      .members-page .members .member .info {
        display: none; }
      .members-page .members .member .contact {
        display: none; }
      .members-page .members .member .tag-box {
        visibility: hidden;
        opacity: 0;
        margin-top: -18px;
        -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
      .members-page .members .member .send-message {
        opacity: 0;
        transform: scale(0.9);
        -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .members-page .no-member-available {
    padding: 140px 0 160px;
    text-align: center;
    color: #1692d1; }

/* ----------------------------------------------------------------
 * Member
 * --------------------------------------------------------------*/
.member {
  display: inline-block;
  width: 25%;
  vertical-align: top;
  text-align: center;
  box-sizing: border-box;
  padding: 0 10px 20px; }
  .member .portrait {
    width: 166px;
    height: 166px;
    margin: auto;
    border-radius: 83px;
    background-position: center;
    background-size: cover;
    background-color: rgba(102, 102, 102, 0.2); }
    @media only screen and (max-width: 800px) {
      .member .portrait {
        width: 116.2px;
        height: 116.2px; } }
    .member .portrait .send-message {
      border-radius: 83px;
      display: table;
      width: 100%;
      height: 100%;
      background-color: rgba(22, 146, 209, 0.9); }
      .member .portrait .send-message a {
        color: white;
        display: table-cell;
        vertical-align: middle;
        text-align: center; }
  .member .name {
    margin-top: 15px;
    text-transform: none; }
    @media only screen and (max-width: 800px) {
      .member .name {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14.4px; } }
  .member .info {
    margin-top: 10px;
    padding: 0; }
    @media only screen and (max-width: 800px) {
      .member .info {
        margin-top: 7px;
        font-size: 12px;
        line-height: 14.4px; } }
    .member .info p {
      padding: 0;
      margin-bottom: 10px; }
      @media only screen and (max-width: 800px) {
        .member .info p {
          margin-bottom: 7px; } }
  .member .city {
    padding-top: 3px;
    padding-bottom: 5px; }
    @media only screen and (max-width: 800px) {
      .member .city {
        font-size: 12px;
        line-height: 14.4px; } }
    .member .city .tag {
      display: inline-block; }
  .member .contact {
    width: 100%;
    display: inline-block;
    font-weight: 700;
    padding: 0 7px 2px;
    box-sizing: border-box;
    border-radius: 3px; }
    @media only screen and (max-width: 800px) {
      .member .contact {
        font-size: 12px;
        line-height: 14.4px; } }
    .member .contact:hover {
      color: white;
      background-color: #1692d1; }
  .member .tag-box .tags .tag {
    color: white;
    display: inline-block;
    background-color: #1692d1;
    margin: 1px 1px 0 0;
    padding: 0 3px 1px; }
  .member .tag-box .skills .tag {
    background-color: #bbca00; }
  .member .tag-box .hobbies .tag {
    background-color: #f5ab00; }

/* ----------------------------------------------------------------
 * SPOTS-PAGE
 * --------------------------------------------------------------*/
.spots-page .header-box {
  position: relative;
  overflow: hidden; }
  .spots-page .header-box .keyvisual {
    padding-bottom: 30px; }
    .spots-page .header-box .keyvisual .content-box {
      position: relative; }
      .spots-page .header-box .keyvisual .content-box .title {
        text-align: center;
        padding-top: 70px;
        padding-bottom: 70px; }
        @media only screen and (max-width: 800px) {
          .spots-page .header-box .keyvisual .content-box .title {
            padding-top: 40px;
            padding-bottom: 40px; } }
      .spots-page .header-box .keyvisual .content-box .desc {
        position: relative;
        z-index: 1;
        vertical-align: top;
        color: white;
        display: inline-block;
        width: 50%; }
        @media only screen and (max-width: 800px) {
          .spots-page .header-box .keyvisual .content-box .desc {
            width: 100%; } }
      .spots-page .header-box .keyvisual .content-box .map {
        position: relative;
        vertical-align: top;
        display: inline-block;
        width: 70%;
        margin-left: -20%;
        margin-top: -30px; }
        @media only screen and (max-width: 800px) {
          .spots-page .header-box .keyvisual .content-box .map {
            margin-left: -25%;
            width: 130%; } }

.spots-page .lakes .lake-teaser:nth-child(3n+0) {
  background-color: #1692d1; }

.spots-page .lakes .lake-teaser:nth-child(3n+1) {
  background-color: #bbca00; }

.spots-page .lakes .lake-teaser:nth-child(3n+2) {
  background-color: #f5ab00; }

.spots-page .lakes .lake-teaser .weather-station {
  position: absolute;
  right: 0;
  background-color: transparent;
  width: 100%;
  height: 100%; }
  .spots-page .lakes .lake-teaser .weather-station .sensors {
    top: 50%;
    padding-right: 10%; }
    .spots-page .lakes .lake-teaser .weather-station .sensors .sensor {
      width: 90px;
      height: 90px;
      transform: translateY(-55px); }
    @media only screen and (max-width: 800px) {
      .spots-page .lakes .lake-teaser .weather-station .sensors .temperature, .spots-page .lakes .lake-teaser .weather-station .sensors .rain {
        display: none; } }
    @media only screen and (max-width: 500px) {
      .spots-page .lakes .lake-teaser .weather-station .sensors {
        display: none; } }

/* ----------------------------------------------------------------
 * Lake-Page
 * --------------------------------------------------------------*/
.lake-page .header-box .keyvisual {
  padding: 100px 0; }
  @media only screen and (max-width: 500px) {
    .lake-page .header-box .keyvisual {
      padding: 70px 0; } }
  .lake-page .header-box .keyvisual .content-box {
    position: relative;
    text-align: center; }
    .lake-page .header-box .keyvisual .content-box .title {
      padding: 0 10px 10px; }
      @media only screen and (max-width: 500px) {
        .lake-page .header-box .keyvisual .content-box .title {
          font-size: 42px;
          line-height: 39px; } }
    .lake-page .header-box .keyvisual .content-box .spots-overview {
      margin: auto; }

.lake-page .public-webcam {
  position: relative;
  margin-bottom: 60px; }
  .lake-page .public-webcam img {
    position: relative;
    width: 100%; }

.lake-page .webcam-control-button-box {
  text-align: center; }
  .lake-page .webcam-control-button-box .webcam-control-button {
    cursor: pointer;
    display: block;
    padding: 20px;
    color: #FFFFFF;
    background-color: #c94949; }
    .lake-page .webcam-control-button-box .webcam-control-button:hover {
      background-color: rgba(201, 73, 73, 0.9); }

.lake-page .member-webcams {
  margin-bottom: 60px;
  position: relative; }
  .lake-page .member-webcams .member-webcam {
    display: inline-block;
    width: 50%;
    position: relative;
    cursor: pointer; }
    .lake-page .member-webcams .member-webcam .name {
      position: absolute;
      background-color: #1692d1;
      padding: 3px 5px;
      color: #FFFFFF;
      top: 5%;
      left: 3%; }
    .lake-page .member-webcams .member-webcam img {
      position: relative;
      width: 100%; }
  .lake-page .member-webcams .member-webcam-zoom-box {
    visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    .lake-page .member-webcams .member-webcam-zoom-box .member-webcam {
      width: 100%; }
      .lake-page .member-webcams .member-webcam-zoom-box .member-webcam .name {
        position: absolute;
        background-color: #1692d1;
        padding: 3px 5px;
        color: #FFFFFF;
        top: 10px;
        left: 10px; }

.lake-page .article-text {
  margin-top: 40px; }

.lake-page .section-title {
  color: #1692d1;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px 20px;
  text-align: center; }

.lake-page .spots-map img {
  width: 100%; }

.lake-page .spots .spot:nth-child(3n+2) {
  background-color: #1692d1; }

.lake-page .spots .spot:nth-child(3n+0) {
  background-color: #bbca00; }

.lake-page .spots .spot:nth-child(3n+1) {
  background-color: #f5ab00; }

.lake-page .spots .spot .header {
  height: 300px;
  position: relative;
  box-sizing: border-box; }
  @media only screen and (max-width: 500px) {
    .lake-page .spots .spot .header {
      height: 200px; } }
  .lake-page .spots .spot .header:hover .photo {
    opacity: 1; }
  .lake-page .spots .spot .header .photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    -webkit-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .lake-page .spots .spot .header .info {
    padding-top: 30px; }
    @media only screen and (max-width: 500px) {
      .lake-page .spots .spot .header .info {
        padding-top: 20px; } }
    .lake-page .spots .spot .header .info .wind-directions {
      width: 40px;
      height: 40px;
      border-radius: 40px; }
    .lake-page .spots .spot .header .info .name {
      position: relative;
      display: inline-block;
      margin-left: 10px; }
      @media only screen and (max-width: 500px) {
        .lake-page .spots .spot .header .info .name {
          display: block;
          margin: 10px 0 0; } }
    .lake-page .spots .spot .header .info .coords {
      display: block;
      margin-top: 2px;
      margin-bottom: 5px;
      margin-left: 55px;
      position: relative;
      color: white; }
      @media only screen and (max-width: 500px) {
        .lake-page .spots .spot .header .info .coords {
          margin: 1px 0 0; } }
    .lake-page .spots .spot .header .info .allowed {
      position: relative;
      margin-left: 55px; }
      @media only screen and (max-width: 500px) {
        .lake-page .spots .spot .header .info .allowed {
          margin: 10px 0 0; } }

@media only screen and (max-width: 800px) {
  .lake-page .spots .spot .desc {
    padding: 15px 0 30px; } }

.lake-page .spots .spot .desc .left, .lake-page .spots .spot .desc .right {
  color: white;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 30px;
  width: 50%; }
  @media only screen and (max-width: 800px) {
    .lake-page .spots .spot .desc .left, .lake-page .spots .spot .desc .right {
      width: 100%;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px; } }

.lake-page .spots .spot .desc .left {
  padding-left: 0; }

.lake-page .spots .spot .desc .right {
  padding-right: 0; }
